@import '../../assets/styles/vars.scss';

.datenschutz__container {
    font-family: $text-font;
    color: $company-color;
    padding: 80px 200px;
    text-align: center;
    @media (max-width: $breakpoint) {
      padding: 100px 24px 40px 24px !important;
    }
    @media (max-width: 768px){
        padding: 100px 24px 40px 24px;
    }
    @media (max-width: 1024px){
        padding: 100px 40px 40px 40px;
    }
    @media (max-width: 1400px){
        padding: 100px 40px 40px 40px;
    }
    a {
      word-break: break-word;
    }
    .datenschutz__header {
      font-family: $header-font !important;
      font-size: 40px;
      color: $company-color;
      margin-bottom: 40px;
      @media (max-width: $breakpoint) {
        font-size: 32px;
      }
    }
  }