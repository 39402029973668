@import '../../assets/styles/vars.scss';

.contact__bg__image {
    background-image: url('https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357307/contact_fl9irk.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: 80vh;
    @media (max-width: $breakpoint) {
        height: 50vh !important;
    }
    .contact__title {
        padding-top: 280px;
        font-family: $header-font;
        color: $company-color;
        font-size: 48px;
        margin-bottom: 0px;
        text-align: center;
        z-index: 10;
        @media (max-width: $breakpoint) {
            font-size: 32px !important;
        }
        @media (max-width: 768px){
            font-size: 40px;
        }
    }
    .contact__subtitle {
        font-family: $text-font;
        color: $company-color;
        font-size: 24px;
        margin-bottom: 16px;
        text-align: center;
        @media (max-width: $breakpoint) {
            font-size: 18px !important;
        }
        @media (max-width: 768px){
            font-size: 20px;
        }
    }
}
.contact__container {
    padding: 0px 200px 0px 200px;
    @media (max-width: $breakpoint) {
        padding: 120px 24px 24px 24px !important;
    }
    @media (max-width: 768px){
        padding: 120px 24px 24px 24px;
    }
    @media (max-width: 1024px){
        padding: 120px 40px 80px 40px;
    }
    @media (max-width: 1400px){
        padding: 120px 80px 80px 80px;
    }
    .contact__row {
        text-align: center;
        margin: 120px auto 72px auto;
        @media (max-width: $breakpoint) {
            margin: 40px auto !important;
        }
        @media (max-width: 1024px){
            margin: 16px auto;
        }
        .contact__col__mail {
            background-color: white;
            padding: 32px 0px;
            margin: -200px 32px 0px 32px;
            transform: scale(1);
            transition: .7s;
            @media (max-width: $breakpoint) {
                padding: 16px 0px !important;
                margin: -200px auto 100px auto !important;
            }
            &:hover {
                transform: scale(1.03);
                box-shadow: 0px 0px 10px rgb(175, 175, 175);
            }
            i {
                font-size: 56px;
                color: $company-color;
                margin-bottom: 24px;
                @media (max-width: $breakpoint) {
                    font-size: 48px;
                }
            }
            a {
                font-family: $text-font;
                color: $company-color;
                font-size: 20px;
                margin-bottom: -20px;
                @media (max-width: $breakpoint) {
                    font-size: 18px;
                }
            }
        }
        .contact__col__phone {
            background-color: white;
            padding: 32px 0px;
            margin: -200px 32px 0px 32px;
            transform: scale(1);
            transition: .7s;
            @media (max-width: $breakpoint) {
                padding: 16px 0px !important;
                margin: -88px auto 216px auto !important;
            }
            @media (max-width: 768px){
                margin: 16px 32px 0px 32px;
            }
            &:hover {
                transform: scale(1.03);
                box-shadow: 0px 0px 10px rgb(175, 175, 175);
            }
            i {
                font-size: 56px;
                color: $company-color;
                margin-bottom: 24px;
                @media (max-width: $breakpoint) {
                    font-size: 48px;
                }
            }
            a {
                font-family: $text-font;
                color: $company-color;
                font-size: 20px;
                margin-bottom: -20px;
                @media (max-width: $breakpoint) {
                    font-size: 18px;
                }
            }
        }
    }
}
.contact__container__map {
    padding: 0px 0px 0px 240px;
    margin-bottom: 40px;
    @media (max-width: $breakpoint) {
        margin-top: -240px !important;
        padding: 0px 0px 0px 40px !important;
    }
    @media (max-width: 768px){
        padding: 0px 16px 0px 40px;
    }
    @media (max-width: 1024px){
        padding: 0px 0px 0px 80px;
    }
    @media (max-width: 1400px){
        padding: 0px 0px 0px 80px;
    }
    .contact__row__map {
        width: 87%;
        @media (max-width: $breakpoint) {
            width: 97% !important;
        }
        @media (max-width: 768px){
            width: 100%;
        }
        @media (max-width: 1024px){
            width: 95%;
        }
        @media (max-width: 1400px){
            width: 94%;
        }
        .contact__map {
            height: 480px;
            margin: 0px auto 80px auto;
            @media (max-width: $breakpoint) {
                margin-bottom: 0px;
                height: 320px;
            }
            @media (max-width: 768px){
                margin-bottom: 0px;
            }
        }
        .contact__map__desc {
            background-color: white;
            height: 480px;
            padding: 16px 32px !important;
            @media (max-width: $breakpoint) {
                text-align: center;
            }
            @media (max-width: 768px){
                text-align: center;
            }
            .contact__map__desc__img {
                width: 240px;
                margin-bottom: 16px;
                @media (max-width: 1024px){
                    width: 220px !important;
                    margin-top: 16px;
                }
                @media (max-width: 1400px){
                    width: 170px;
                }
            }
            .contact__map__desc__contact {
                font-family: $text-font;
                font-size: 24px;
                margin-bottom: 4px;
                margin-top: 16px;
                @media (max-width: 1024px){
                    font-size: 20px;
                }
                @media (max-width: 1400px){
                    font-size: 20px;
                }
            }
            .contact__map__desc__address {
                font-family: $text-font;
                margin-bottom: 8px;
                @media (max-width: 1024px){
                    font-size: 14px;
                }
                @media (max-width: 1400px){
                    font-size: 14px;
                }
            }
            .contact__map__desc__link {
                font-family: $text-font;
                color: $company-color;
                @media (max-width: 1024px){
                    font-size: 14px;
                }
                @media (max-width: 1400px){
                    font-size: 14px;
                }
            }
        }
        .col-lg-9 {
            padding: 0px !important;
        }
    }
}