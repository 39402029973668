@import '../../assets/styles/vars.scss';

.main__divider {
	position: relative;
	height: 1px;
	@media (max-width: $breakpoint) {
		margin: 16px 0px;
	}
}

.div__transparent:before {
	content: "";
	position: absolute;
	top: 0;
	left: 5%;
	right: 5%;
	width: 90%;
	height: 1px;
	background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
}

.div__dot:after {
	content: "";
	position: absolute;
	z-index: 1;
	top: -9px;
	left: calc(50% - 9px);
	width: 18px;
	height: 18px;
	background-color: $company-color;
	border: 1px solid rgb(48,49,51);
    border-radius: 50%;
	box-shadow: inset 0 0 0 2px #E9E6E6, 0 0 0 4px #E9E6E6;
	@media (max-width: $breakpoint) {
		box-shadow: inset 0 0 0 2px #D3CFD0, 0 0 0 4px #D3CFD0;
	}
}