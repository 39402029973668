@import '../../assets/styles/vars.scss';

.home__cards__container {
    padding: 80px 200px;
    @media (max-width: $breakpoint) {
        width: 100vw;
        padding: 24px 16px !important;
    }
    @media (max-width: 768px){
        padding: 24px;
    }
    @media (max-width: 1024px){
        padding: 40px;
    }
    @media (max-width: 1400px){
        padding: 80px;
    }
    .home__cards__header {
        font-family: $header-font;
        color: $company-color;
        font-size: 40px;
        margin-bottom: 16px;
        text-align: center;
        @media (max-width: $breakpoint) {
            font-size: 24px;
        }
    }
    .home__cards__link {
        text-decoration: none !important;
        color: $company-color;
    }
    .home__cards__card {
        border: none;
        border-radius: 0px;
        transform: scale(1);
        transition: .7s;
        &:hover {
            transform: scale(1.03);
            box-shadow: 0px 0px 10px rgb(175, 175, 175);
        }
        @media (max-width: $breakpoint) {
            margin-bottom: 16px;
        }
        @media (max-width: 768px){
            margin-bottom: 16px;
        }
    }
    .home__cards__img {
        border-radius: 0px;
        height: 300px;
        object-fit: cover;
    }
    .home__cards__title {
        text-align: center;
        font-family: $header-font;
        font-size: 24px;
        margin-bottom: 0px;
    }
    .home__cards__sold {
        text-align: center;
        font-family: $text-font;
        opacity: .7;
    }
}