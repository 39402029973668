@import '../../assets//styles/vars.scss';

.quality__container {
    padding: 140px 200px 80px 200px;
    @media (max-width: $breakpoint) {
        padding: 120px 0px 40px 0px !important;
    }
    @media (max-width: 768px){
        padding: 120px 24px 80px 24px;
    }
    @media (max-width: 1024px){
        padding: 120px 40px 80px 40px;
    }
    @media (max-width: 1400px){
        padding: 120px 80px 80px 80px;
    }
    .quality__section__title {
        font-family: $header-font;
        color: $company-color;
        font-size: 48px;
        margin-bottom: 16px;
        text-align: center;
        @media (max-width: $breakpoint) {
            font-size: 40px;
            padding: 0px 24px;
        }
        @media (max-width: 768px){
            font-size: 36px;
        }
    }
    .quality__section__desc {
        font-family: $text-font;
        color: $company-color;
        opacity: .8;
        margin-bottom: 80px;
        text-align: center;
        @media (max-width: $breakpoint) {
            padding: 0px 24px;
            margin-bottom: -40px;
        }
        @media (max-width: 1024px){
            margin-bottom: -40px;
        }
    }
    .quality__row__container {
        margin: 80px auto;
        @media (max-width: $breakpoint) {
            padding: 0px 24px;
        }
    }
    .quality__img1 {
        object-fit: cover;
        margin-top: 80px;
    }
    .horizontalSlider___281Ls {
        margin-bottom: 16px;
        @media (max-width: $breakpoint) {
            margin-bottom: 0px;
        }
    }
    .carousel__dot-group {
        text-align: center;
        .dot___3c3SI {
            background-color: $company-color;
            opacity: .5;
            border: none;
            margin: 0 8px;
            height: 4px;
            width: 40px;
            @media (max-width: $breakpoint) {
                height: 2px !important;
                width: 24px !important;
                margin: 16px -32px !important;
            }
            @media (max-width: 768px){
                height: 1px;
                width: 24px;
                margin: 0px -32px;
            }
            @media (max-width: 1024px){
                height: 2px;
                width: 26px;
                margin: 0px -32px;
            }
            @media (max-width: 1400px){
                height: 3px;
                width: 28px;
                margin: 0px -40px;
            }
            @media (max-width: 1620px){
                height: 3px;
                width: 32px;
                margin: 0px -48px;
            }
        }
        .carousel__dot--selected {
            opacity: 1 !important;
        }
    }
    .buttonBack___1mlaL {
        color: $company-color;
        border: none;
        font-size: 20px;
        background-color: transparent;
        @media (max-width: 768px){
            font-size: 16px;
        }
    }
    .buttonNext___2mOCa {
        color: $company-color;
        border: none;
        font-size: 20px;
        background-color: transparent;
        @media (max-width: 768px){
            font-size: 16px;
        }
    }
}