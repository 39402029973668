@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300&display=swap');

$company-color: #575656;
$company-color-opa: #57565686;
$company-color-light: #b6adac80;
$company-color-second: #C0B9BA;

$header-font: 'Frank Ruhl Libre', serif;
$text-font: 'Roboto', sans-serif;

$company-spacing: 80px;

$breakpoint: 640px;