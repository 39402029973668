@import '../../assets/styles/vars.scss';

.project__map {
    width: 77vw;
    height: 560px;
    margin: 56px auto 0px auto;
    @media (max-width: $breakpoint) {
        width: 90vw !important;
        margin-bottom: -40px;
    }
    @media (max-width: 768px){
        width: 90vw !important;
    }
    @media (max-width: 1024px){
        width: 92vw !important;
    }
    @media (max-width: 1400px){
        width: 85vw;
    }
}