@import '../../assets/styles/vars.scss';

.home__header {
    background-image: url('https://res.cloudinary.com/dfdon3lw3/image/upload/v1617357391/header_gymvxn.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    width: 100vw;
}
.header__jumbotron {
    background-color: transparent !important;
    color: white;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 20px;
    margin-bottom: 0px;
    height: 100vh;
    & h1 {
        font-size: 70px;
        font-family: $header-font;
        color: $company-color;
        padding-top: 320px !important;
        @media (max-width: $breakpoint) {
            font-size: 40px !important;
        }
        @media (max-width: 768px){
            padding-top: 240px !important;
            font-size: 56px;
        }
        @media (max-width: 1024px){
            padding-top: 240px !important;
            font-size: 56px;
        }
        @media (max-width: 1400px){
            padding-top: 240px !important;
            font-size: 60px;
        }
    }
}

.home__section1 {
    text-align: center;
    padding: 80px 200px;
    @media (max-width: $breakpoint) {
        width: 100vw;
        padding: 24px 16px !important;
    }
    @media (max-width: 768px){
        padding: 24px;
    }
    @media (max-width: 1024px){
        padding: 40px;
    }
    @media (max-width: 1400px){
        padding: 80px;
    }
    .home__section1__header {
        font-family: $header-font;
        color: $company-color;
        font-size: 40px;
        margin-bottom: 16px;
        @media (max-width: $breakpoint) {
            font-size: 32px !important;
        }
        @media (max-width: 768px){
            font-size: 36px;
        }
    }
    .home__section1__text {
        font-family: $text-font;
        color: $company-color;
        opacity: .8;
        margin-bottom: 32px;
        @media (max-width: $breakpoint) {
            font-size: 16px;
        }
    }
}

.home__card__section {
    padding: 80px 200px;
    @media (max-width: $breakpoint) {
        width: 100vw !important;
        padding: 24px 16px 32px 16px !important;
    }
    @media (max-width: 768px){
        width: 100vw;
        padding: 24px;
    }
    @media (max-width: 1024px){
        padding: 40px;
    }
    @media (max-width: 1400px){
        padding: 80px;
    }
    .home__card__section__title {
        text-align: center;
        font-family: $header-font;
        color: $company-color;
        font-size: 40px;
        margin-bottom: 16px;
        @media (max-width: $breakpoint) {
            font-size: 32px !important;
        }
        @media (max-width: 768px){
            font-size: 36px;
        }
    }
}

.home__section2 {
    text-align: center;
    padding: 80px 200px;
    @media (max-width: $breakpoint) {
        width: 100vw;
        padding: 24px 16px 80px 16px !important;
    }
    @media (max-width: 768px){
        padding: 24px 24px 80px 24px;
    }
    @media (max-width: 1024px){
        padding: 40px;
    }
    @media (max-width: 1400px){
        padding: 80px;
    }
    .home__section2__header {
        font-family: $header-font;
        color: $company-color;
        font-size: 40px;
        margin-bottom: 16px;
        @media (max-width: $breakpoint) {
            font-size: 32px;
        }
        @media (max-width: 768px){
            font-size: 36px;
        }
    }
    .home__section2__text {
        font-family: $text-font;
        color: $company-color;
        opacity: .8;
        margin-bottom: 32px;
        @media (max-width: $breakpoint) {
            font-size: 16px;
        }
    }
}

.home__a__button {
    background-color: transparent !important;
    color: $company-color !important;
    border: 1px solid $company-color !important;
    padding: 16px 32px !important;
    border-radius: 0px !important;
    font-family: $text-font;
    font-size: 18px !important;
    transition: .5s !important;
    text-decoration: none;
    &:hover {
        background-color: $company-color !important;
        color: white !important;
        border: 1px solid $company-color !important;
        text-decoration: none;
    }
}