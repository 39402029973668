@import '../../assets/styles/vars.scss';

.all__projects__container {
    padding: 140px 200px 80px 200px;
    @media (max-width: $breakpoint) {
        width: 100vw !important;
        padding: 120px 16px !important;
    }
    @media (max-width: 768px){
        padding: 120px 24px 80px 24px;
    }
    @media (max-width: 1024px){
        padding: 120px 40px 80px 40px;
    }
    @media (max-width: 1400px){
        padding: 120px 80px 80px 80px;
    }
    .all__projects__header {
        font-family: $header-font;
        color: $company-color;
        font-size: 40px;
        margin-bottom: 16px;
        text-align: center;
        @media (max-width: 768px){
            font-size: 36px;
        }
    }
    .all__projects__subheader {
        font-family: $text-font;
        color: $company-color;
        opacity: .8;
        margin-bottom: 32px;
        text-align: center;
    }
}