@import '../../assets/styles/vars.scss';

.footer__container {
    background-color: $company-color-light;
    padding: 40px 200px 8px 200px;
    @media (max-width: $breakpoint) {
        width: 100vw !important;
        padding: 24px 8px !important;
    }
    @media (max-width: 1024px){
        padding: 40px;
    }
    @media (max-width: 1400px){
        padding: 40px 80px;
    }
    .footer__title {
        font-family: $text-font;
        font-size: 22px;
        color: $company-color;
        @media (max-width: $breakpoint) {
            font-size: 20px;
            margin-bottom: 0px;
        }
    }
    img {
        margin-top: 8px;
        height: 120px;
        object-fit: cover;
        @media (max-width: $breakpoint) {
            height: 80px;
            margin-bottom: 8px;
        }
        @media (max-width: 1024px){
            margin-top: 40px;
            height: 80px;
        }
        @media (max-width: 1400px){
            margin-top: 24px;
            height: 100px;
        }
    }
    .footer__img__subtitle {
        margin-top: 8px;
        font-family: $text-font;
        font-size: 18px;
        color: $company-color;
        @media (max-width: $breakpoint) {
            display: none;
        }
        @media (max-width: 1024px){
            display: none;
        }
    }
    a {
        text-decoration: none;
        color: $company-color;
        font-family: $text-font;
        &:hover {
            color: $company-color;
        }
    }
    .footer__map {
        width: 300px;
        height: 120px;
        margin: 0px auto 8px auto;
        @media (max-width: $breakpoint) {
            height: 160px !important;
            width: 320px !important;
        }
        @media (max-width: 1024px){
            width: 240px;
            height: 80px;
        }
    }
    .footer__copyright {
        text-align: center;
        font-family: $text-font;
        margin-top: 40px;
    }
    .col-12 {
        @media (max-width: $breakpoint) {
            margin-bottom: 16px;
        }
    }
}