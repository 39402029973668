@import '../../assets/styles/vars.scss';

.project__template__jumbotron {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    height: 800px;
    @media (max-width: $breakpoint) {
        height: 80vh;
        background-size: cover;
        background-attachment: fixed;
        background-repeat: no-repeat;
    }
    .project__template__jumbotron__title {
        text-align: center;
        font-family: $header-font;
        font-size: 56px;
        margin: 320px auto 0px auto;
        width: 64%;
        background-color: #ffffff85;
        color: $company-color;
        @media (max-width: $breakpoint) {
            font-size: 32px !important;
            width: 80vw !important;
            background-color: #ffffffa2 !important;
        }
        @media (max-width: 768px){
            font-size: 48px;
            width: 80vw;
        }
        @media (max-width: 1024px){
            font-size: 48px;
            width: 80vw;
        }
        @media (max-width: 1400px){
            width: 80vw;
        }
    }
    .project__template__jumbotron__sold {
        text-align: center;
        font-family: $text-font;
        font-size: 24px;
        background-color: #ffffff85;
        width: 20%;
        margin: 16px auto;
        color: $company-color;
        @media (max-width: $breakpoint) {
            width: 64vw !important;
            background-color: #ffffffa2;
        }
        @media (max-width: 768px){
            width: 40vw;
        }
        @media (max-width: 1024px){
            width: 40vw;
        }
        @media (max-width: 1400px){
            width: 40vw;
        }
    }
}

.project__template__section1 {
    text-align: center;
    padding: 80px 200px;
    @media (max-width: $breakpoint) {
        padding: 0px 24px 40px 24px !important;
    }
    @media (max-width: 768px){
        padding: 0px 24px 40px 24px;
    }
    @media (max-width: 1024px){
        padding: 0px 40px 40px 40px;
    }
    @media (max-width: 1400px){
        padding: 0px 40px 40px 40px;
    }
    .project__template__section1__title {
        font-family: $header-font;
        font-size: 48px;
        @media (max-width: $breakpoint) {
            font-size: 32px !important;
        }
    }
    .project__template__section1__desc {
        font-family: $text-font;
    }
}

.project__template__section2 {
    text-align: center;
    padding: 80px 200px;
    @media (max-width: $breakpoint) {
        padding: 24px 24px 40px 24px !important;
    }
    @media (max-width: 768px){
        padding: 40px 24px 40px 24px;
    }
    @media (max-width: 1024px){
        padding: 40px 40px 40px 40px;
    }
    @media (max-width: 1400px){
        padding: 40px 40px 40px 40px;
    }
    .project__template__section2__title {
        font-family: $header-font;
        font-size: 48px;
        @media (max-width: $breakpoint) {
            font-size: 32px !important;
        }
    }
    .project__template__section2__img {
        width: 640px;
        height: 320px;
        object-fit: cover;
        margin-bottom: 32px;
        @media (max-width: $breakpoint) {
            width: 100% !important;
            height: 200px !important;
        }
        @media (max-width: 1024px){
            width: 100%;
            height: 260px;
        }
        @media (max-width: 1400px){
            width: 100%;
            height: 300px;
        }
        @media (max-width: 1650px){
            width: 100%;
            height: 300px;
        }
    }
}

.project__template__section3 {
    text-align: center;
    padding: 80px 200px;
    @media (max-width: $breakpoint) {
        padding: 24px 24px 40px 24px !important;
    }
    @media (max-width: 768px){
        padding: 40px 24px 40px 24px;
    }
    @media (max-width: 1024px){
        padding: 40px 40px 40px 40px;
    }
    @media (max-width: 1400px){
        padding: 40px 40px 40px 40px;
    }
    .project__template__section3__title {
        font-family: $header-font;
        font-size: 48px;
        @media (max-width: $breakpoint) {
            font-size: 32px !important;
        }
    }
    .project__template__section3__desc {
        font-family: $text-font;
    }
    .project__template__map {
        width: 75vw;
        height: 400px;
        margin: 0px auto;
        @media (max-width: 768px){
            width: 90vw;
            height: 320px;
            margin: 40px auto;
        }
        @media (max-width: 1024px){
            width: 90vw;
            height: 320px;
            margin: 40px auto;
        }
        @media (max-width: 1400px){
            width: 90vw;
            height: 320px;
            margin: 40px auto;
        }
    }
}
.project__template__section4 {
    padding: 80px 200px;
    @media (max-width: $breakpoint) {
        padding: 24px 24px 40px 24px !important;
    }
    @media (max-width: 768px){
        padding: 40px 24px 40px 24px;
    }
    @media (max-width: 1024px){
        padding: 40px 40px 40px 40px;
    }
    @media (max-width: 1400px){
        padding: 40px 40px 40px 40px;
    }
    .project__template__section4__title {
        font-family: $header-font;
        font-size: 48px;
        text-align: center;
        @media (max-width: $breakpoint) {
            font-size: 32px;
        }
    }
    .project__template__section4__desc {
        font-family: $text-font;
        text-align: center;
        margin-bottom: 40px;
    }
    .project__template__section4__img {
        width: 640px;
        height: 320px;
        object-fit: cover;
        margin-bottom: 32px;
        @media (max-width: $breakpoint) {
            width: 100% !important;
            height: 200px !important;
            &:hover {
                transform: scale(1.2);
                z-index: 20;
            }
        }
        @media (max-width: 768px){
            height: 260px;
            object-fit: cover;
        }
        @media (max-width: 1024px){
            width: 100%;
            height: 260px;
        }
        @media (max-width: 1400px){
            width: 100%;
            height: 300px;
        }
        @media (max-width: 1650px){
            width: 100%;
            height: 300px;
        }
    }
    .project__template__section4__li {
        font-family: $text-font;
    }
}

.project__template__section5 {
    padding: 80px 200px;
    @media (max-width: $breakpoint) {
        padding: 24px 24px 40px 24px !important;
    }
    @media (max-width: 768px){
        padding: 40px 24px 40px 24px;
    }
    @media (max-width: 1024px){
        padding: 40px 40px 40px 40px;
    }
    @media (max-width: 1400px){
        padding: 40px 40px 40px 40px;
    }
    .project__template__section5__title {
        font-family: $header-font;
        font-size: 48px;
        text-align: center;
        @media (max-width: $breakpoint) {
            font-size: 32px;
        }
    }
    .project__template__section5__img {
        width: 640px;
        height: 400px;
        margin-bottom: 32px;
        @media (max-width: $breakpoint) {
            width: 100% !important;
            height: 200px !important;
            &:hover {
                transform: scale(1.2);
                z-index: 20;
            }
        }
        @media (max-width: 768px){
            height: 260px;
            object-fit: cover;
        }
        @media (max-width: 1024px){
            width: 100%;
            height: 260px;
        }
        @media (max-width: 1400px){
            width: 100%;
            height: 300px;
        }
        @media (max-width: 1650px){
            width: 100%;
            height: 300px;
        }
    }
}

.project__template__section6 {
    text-align: center;
    padding: 80px 200px;
    @media (max-width: $breakpoint) {
        padding: 24px 24px 40px 24px !important;
    }
    @media (max-width: 768px){
        padding: 40px 24px 40px 24px;
    }
    @media (max-width: 1024px){
        padding: 40px 40px 40px 40px;
    }
    @media (max-width: 1400px){
        padding: 40px 40px 40px 40px;
    }
    .project__template__section6__title {
        font-family: $header-font;
        font-size: 48px;
        margin-bottom: -12px;
        @media (max-width: $breakpoint) {
            font-size: 32px;
            margin-bottom: 0px;
        }
    }
    .project__template__section6__subtitle {
        font-family: $text-font;
        font-size: 18px;
        margin-bottom: 40px;
        @media (max-width: $breakpoint) {
            font-size: 16px;
        }
    }
    i {
        font-size: 56px;
        color: $company-color;
        margin-bottom: 8px;
    }
    a {
        font-size: 18px;
        color: $company-color;
        font-family: $text-font;
        &:hover {
            color: $company-color;
        }
    }
}