@import '../../assets/styles/vars.scss';

.project__card__container {
    margin-bottom: 40px;
}

.project__card {
    border: none !important;
    border-radius: 0px !important;
    box-shadow: 0px 0px 10px rgb(204, 204, 204);
    margin-bottom: 32px;
    text-align: center;
    transition: .7s;
    &:hover {
        transform: scale(1.02);
    }
    .project__card__img {
        border-radius: 0px;
        height: 400px;
        object-fit: cover;
    }
    .project__card__header {
        font-family: $header-font;
        text-align: center;
        font-size: 32px !important;
    }
    .project__card__subheader {
        font-family: $text-font;
        text-align: center;
        @media (max-width: $breakpoint) {
            margin: 0px 0px 16px 0px !important;
        }
        @media (max-width: 1024px){
            font-size: 14px;
        }
    }
}