@import '../../assets/styles/vars.scss';

.navbar {
    color: $company-color-second !important;
    background-image: linear-gradient(to right, #FDFDFD , #DFDCDC) !important;
    z-index: 10 !important;
    font-family: $text-font !important;
    font-size: 16px !important;
    padding: 4px 200px !important;
    position: fixed !important;
    width: 100%;
    @media (max-width: $breakpoint) {
        width: 100vw !important;
        padding: 4px 4px !important;
    }
    @media (max-width: 768px){
        width: 100% !important;
        padding: 4px 24px !important;
    }
    @media (max-width: 1024px){
        padding: 4px 40px !important;
    }
    @media (max-width: 1400px){
        padding: 4px 80px ;
    }
    &:hover {
        color: $company-color-second !important;
        transition: .6s !important;
    }
    .navbar__logo {
        height: 56px;
    }
    .nav-link {
        margin-left: 32px;
        text-align: center;
    }
    
}
