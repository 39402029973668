@import '../../assets/styles/vars.scss';

.main__card {
    border: 1px solid $company-color !important;
    border-radius: 0px 40px 0px 40px !important;
    padding: 0px 40px;
    transform: scale(1);
    transition: .7s;
    height: 260px;
    position: relative;
    &:hover {
        transform: scale(1.03);
        box-shadow: 0px 0px 10px rgb(175, 175, 175);
    }
    @media (max-width: $breakpoint) {
        height: 220px !important;
        margin-bottom: 16px !important;
    }
    @media (max-width: 768px){
        height: 200px;
        margin-bottom: 16px;
    }
    @media (max-width: 1024px){
        height: 260px;
        padding: 0px 16px;
    }
    @media (max-width: 1400px){
        padding: 0px 16px;
        height: 300px;
    }
    @media (max-width: 1620px){
        padding: 0px 16px;
    }
    .main__card__icon {
        text-align: center;
        font-size: 32px;
        color: $company-color;
        @media (max-width: $breakpoint) {
            display: none;
        }
        @media (max-width: 1024px){
            font-size: 24px;
        }
        @media (max-width: 1400px){
            font-size: 24px;
        }
    }
    .main__card__title {
        text-align: center;
        font-family: $header-font;
        font-size: 28px;
        color: $company-color;
        @media (max-width: $breakpoint) {
            font-size: 22px !important;
        }
        @media (max-width: 1024px){
            font-size: 20px;
        }
        @media (max-width: 1400px){
            font-size: 24px;
        }
    }
    .main__card__desc {
        text-align: center;
        font-family: $text-font;
        color: $company-color;
        @media (max-width: $breakpoint) {
            font-size: 14px !important;
        }
        @media (max-width: 1024px){
            font-size: 14px;
        }
    }
}
