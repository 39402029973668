@import '../../assets/styles/vars.scss';

#consent__container {
    background-color: rgba(196, 196, 196, 0.445);
    position: fixed;
    z-index: 10;
    height: 100%;
    width: 100%;
    text-align: center;
    &.hidden {
        display: none;
    }
        .consent__content {
            display: inline-block;
            width: 60%;
            margin-top: 15%;
            padding: 24px 40px 40px 40px;
            border-radius: none;
            background-color: white;
            box-shadow: 0px 0px 10px #c4c4c4;
            @media (max-width: $breakpoint) {
                margin-top: 10%;
                width: 90%;
                padding: 24px 24px 40px 24px;
            }
        .consent__header {
            padding: 15px;
            font-size: 32px;
            background-color: white;
            text-align: center;
            font-family: $header-font;
            @media (max-width: $breakpoint) {
                padding: 15px 15px 0px 15px;
            }
        }
        .consent__text {
            font-size: 16px;
            font-family: $text-font;
        }
        .consent__button__group {
            padding: 10px;
            background-color: white;
            color: white;
            .consent__button__accept {
                background-color: $company-color !important;
                color: white !important;
                border: 1px solid $company-color !important;
                padding: 16px 32px !important;
                border-radius: 0px !important;
                font-family: $text-font;
                font-size: 18px !important;
                transition: .5s !important;
                @media (max-width: $breakpoint) {
                    font-size: 16px !important;
                    padding: 8px 16px !important;
                }
            }
            .consent__button__decline {
                background-color: transparent !important;
                color: $company-color !important;
                border: 1px solid $company-color !important;
                padding: 16px 32px !important;
                border-radius: 0px !important;
                font-family: $text-font;
                font-size: 18px !important;
                transition: .5s !important;
                &:hover {
                    background-color: $company-color !important;
                    color: white !important;
                    border: 1px solid $company-color !important;
                }
                @media (max-width: $breakpoint) {
                    font-size: 16px !important;
                    padding: 8px 16px !important;
                    margin-bottom: 8px;
                }
            }
        }
    }
}