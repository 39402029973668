@import '../../assets/styles/vars.scss';

.main__button {
    background-color: transparent !important;
    color: $company-color !important;
    border: 1px solid $company-color !important;
    padding: 16px 32px !important;
    border-radius: 0px !important;
    font-family: $text-font;
    font-size: 18px !important;
    transition: .5s !important;
    &:hover {
        background-color: $company-color !important;
        color: white !important;
        border: 1px solid $company-color !important;
    }
}
